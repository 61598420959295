<template>
  <div class="class_box">
    <div>
      <div class="Top_box">
        <el-button @click="back" style="margin-left: 10px" round icon="el-icon-back">返回</el-button>
        <span>任务指派</span>
      </div>
      <div class="bottom_box">
        <div class="bottom_box_right">
          <div class="mid">
            <span>发布日期：</span>
            <el-select
              v-model="dateValue"
              style="width: 75px; margin-right: 10px"
              placeholder="请选择"
              @change="changeData2"
            >
              <el-option v-for="item in dateOption" :key="item" :label="item" :value="item"></el-option>
            </el-select>
            <el-select
              v-model="selectValue"
              style="width: 95px; margin-right: 10px"
              placeholder="请选择"
              @change="changeTable"
            >
              <el-option
                v-for="item in option"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <span>类型：</span>
            <el-select
              v-model="typeValue"
              style="width: 122px; margin-right: 10px"
              placeholder="请选择"
              @change="changeTable2"
              clearable
            >
              <el-option
                v-for="item in typeValuOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>

            <el-select
              style="width: 85px; margin-right: 10px"
              v-model="optionChoosevalue"
              @change="changeDone"
              clearable
            >
              <el-option
                v-for="item in optionChoose"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>

            <span>任务完成时间：</span>
            <el-date-picker
              style="margin-right: 10px;width:130px"
              v-model="date1"
              type="date"
              @change="changeData"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <span v-if="selectValue">考核人公司：</span>
            <el-select
              v-if="selectValue"
              style="margin-right: 10px;"
              v-model="company"
              clearable
              placeholder="请选择"
              @change="changCompany"
            >
              <el-option
                v-for="item in companyList"
                :key="item.value"
                :label="item.companyName"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-button
              v-show="isLean"
              type="primary"
              @click="addEvent(0)"
              style="background: #5a8bff"
            >
              指派考试
              <i class="el-icon-circle-plus-outline"></i>
            </el-button>
            <el-button
              v-show="isLean"
              type="primary"
              @click="addEvent(1)"
              style="background: #5a8bff"
            >
              指派学习
              <i class="el-icon-circle-plus-outline"></i>
            </el-button>
            <el-button
              v-show="isSpecial"
              type="primary"
              @click="addEvent(2)"
              style="background: #5a8bff"
            >
              指派专题考试
              <i class="el-icon-circle-plus-outline"></i>
            </el-button>
            <el-button
              v-show="isSpecial"
              type="primary"
              @click="addEvent(3)"
              style="background: #5a8bff"
            >
              指派专题学习
              <i class="el-icon-circle-plus-outline"></i>
            </el-button>

            <span style="margin-top: 3px; margin-left: 10px" v-if="indexE != 0 && changeWork != 4">
              <img
                style="cursor: pointer"
                @click="deltype"
                src="../../assets/images/Examine_img/del.png"
                alt
              />
            </span>
          </div>
          <el-table
            :data="tableData.slice(start, end)"
            style="width: 100%"
            highlight-current-row
            @row-click="handleRow"
            :height="elementHeight"
            :header-cell-style="{
              background: '#EDF0F7',
              color: '#000000',
            }"
            id="table"
          >
            <el-table-column prop="compName" width="250" label="公司"></el-table-column>
            <el-table-column prop="deptName" width="150" label="部门"></el-table-column>
            <el-table-column prop="postName" width="150" label="岗位"></el-table-column>
            <el-table-column prop="studyUserName" label="考核人员" width="130" v-if="selectValue"></el-table-column>
            <el-table-column prop="studyUserCompName" label="考核人员公司" width="200" v-if="selectValue"></el-table-column>
            <el-table-column prop="isTest" label="任务类型" width="180">
              <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.isTest === 1" type="danger">考试</span>
                  <span v-if="scope.row.isTest === 0" type="danger">学习</span>
                  <span v-if="scope.row.isTest === 2" type="danger">专题学习</span>
                  <span v-if="scope.row.isTest === 3" type="danger">专题考试</span>
                  <span v-if="scope.row.isTest === 4" type="danger">审核考试</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="assignUserName" label="发布人" width="130"></el-table-column>
            <el-table-column prop="endTime" label="任务时间" width="130">
              <template slot-scope="scope">
                <span>{{ scope.row.endTime | time }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="任务状态">
              <template slot-scope="scope">
                <div>
                  <span v-if="scope.row.status === 0" type="danger">未完成</span>
                  <span
                    v-if="
                      scope.row.status === 2 &&
                      (scope.row.isTest == 0 || scope.row.isTest == 2)
                    "
                    type="success"
                  >已完成</span>
                  <span
                    v-if="
                      scope.row.status === 2 &&
                      (scope.row.isTest == 1 ||
                        scope.row.isTest == 3 ||
                        scope.row.isTest == 4)
                    "
                    type="danger"
                  >{{ scope.row.score }}分</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="selectValue == 0 ? '操作' : ''">
              <template slot-scope="scope">
                <div v-if="!selectValue">
                  <el-button
                    @click="toTest(scope.row)"
                    :disabled="scope.row.status"
                    v-if="
                      scope.row.isTest === 1 ||
                      scope.row.isTest === 3 ||
                      scope.row.isTest === 4
                    "
                    type="danger"
                  >去考试</el-button>
                  <el-button
                    v-else
                    type="success"
                    :disabled="scope.row.status"
                    @click="toLearn(scope.row)"
                  >去学习</el-button>
                  <!-- :disabled="scope.row.isTest == 4" -->
                  <el-button
                    type="primary"
                    @click="addEvent(4, scope.row)"
                    style="background: #5a8bff"
                  >转派</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog
      title="新增任务指派"
      :visible.sync="dialogVisible"
      width="500px"
      :show-close="true"
      :before-close="handleClose"
    >
      <el-form ref="form" label-width="110px">
        <el-form-item label="任务类型：">
          <span v-show="type === 0">考试</span>
          <span v-show="type === 1">学习</span>
          <span v-show="type === 2">专题考试</span>
          <span v-show="type === 3">专题学习</span>
        </el-form-item>
        <el-form-item label="文件所属公司：" v-show="type !== 3 && type !== 2">
          <el-select style="width: 95%" v-model="Company1" placeholder @change="togetArea">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.companyName"
              :value="{ value: item.id, label: item.companyName }"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文件所属部门：" v-show="type !== 3 && type !== 2">
          <el-select v-model="deptmentName1" placeholder @change="togetPost" style="width: 95%">
            <el-option
              v-for="item in option2"
              :key="item.id"
              :label="item.deptmentName"
              :value="{ value: item.id, label: item.deptmentName }"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文件所属岗位：" v-show="type !== 3 && type !== 2">
          <el-select v-model="Area1.value" placeholder style="width: 95%" @change="changePost">
            <el-option
              v-for="item in option3"
              :key="item.id"
              :label="item.postName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="抽取题目：" v-show="type !== 3 && type !== 2 && type !== 1"></el-form-item>
        <el-form-item v-show="type !== 3 && type !== 2 && type !== 1" label-width="0">
          <el-table
            :data="titlelist2"
            max-height="300px"
            @selection-change="selectionDraw"
            style="width: 100%"
            border
          >
            <el-table-column prop="fileName" label="文件名称" width="150"></el-table-column>
            <el-table-column prop="fileNo" label="文件编号"></el-table-column>
            <el-table-column prop="titleCount" label="总题数"></el-table-column>
            <el-table-column type="selection" width="55"></el-table-column>
          </el-table>
        </el-form-item>

        <el-form-item label="专题列表：" v-show="type == 3 || type == 2">
          <el-select v-model="theme" @change="changeList" placeholder style="width: 95%">
            <el-option v-for="item in option5" :key="item" :label="item.theme" :value="item.theme"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考核人员：">
          <!-- <el-select
            v-show="type == 0"
            v-model="userId"
            placeholder=""
            @change="sss"
            collapse-tags
            filterable
            multiple
            style="width: 95%"
          >
            <el-option
              v-for="item in option4"
              :key="item.value"
              :label="item.userName"
              :value="{ value: item.id, label: item.userName }"
            >
            </el-option>
          </el-select>-->
          <el-input
            style="width: 95%"
            placeholder="请输入内容"
            v-model="numPerson"
            v-show="type == 1 || type == 0 || type == 3 || type == 2"
            @focus="More"
          >
            <template slot="append">
              <span @click="More">更多</span>
            </template>
          </el-input>
          <!-- <el-select
            class="typePerson"
            v-show="type == 3 || type == 2"
            v-model="userId"
            placeholder=""
            @change="sss"
            filterable
            collapse-tags
            multiple
            style="width: 95%"
          >
            <el-option
              v-for="item in option6"
              :key="item.value"
              :label="item.userName"
              :value="{ value: item.id, label: item.userName }"
            >
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-option>
          </el-select>-->
        </el-form-item>
        <el-form-item label="任务完成时间">
          <el-date-picker
            style="width: 95%"
            v-model="date"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addAssignTest">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="转派任务"
      :visible.sync="dialogVisible1"
      width="23%"
      :show-close="true"
      :before-close="handleClose"
    >
      <el-form ref="form" label-width="100px">
        <el-form-item label="转派人员：">
          <!-- <el-select
            v-model="userId"
            placeholder
            @change="sss"
            filterable
            multiple
            style="width: 95%"
            v-loading="loading"
          >
            <el-option
              v-for="item in option6"
              :key="item.value"
              :label="item.userName"
              :value="{ value: item.id, label: item.userName }"
            ></el-option>
          </el-select> -->
          <el-input
            style="width: 95%"
            placeholder="请输入内容"
            v-model="numPerson"
            @focus="More"
          >
            <template slot="append">
              <span @click="More">更多</span>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="任务完成时间">
          <el-date-picker
            style="width: 95%"
            v-model="date"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="changeAssignTest">确 定</el-button>
        <el-button @click="dialogVisible1 = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="筛选区" width="1076px" :visible.sync="dialogVisibleMore">
      <div class="title">
        <div style="margin-bottom: 10px"></div>
        <span style="margin-left: 21px">筛选区</span>
      </div>
      <div class="add_box_top">
        <el-select
          style="width: 136px; height: 40px; margin-right: 4px"
          v-model="Company11"
          placeholder="请选择公司"
          @change="togetArea"
          clearable
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.companyName"
            :value="{ value: item.id, label: item.companyName }"
          ></el-option>
        </el-select>
        <el-select
          v-model="deptmentName11"
          placeholder="请选择部门"
          @change="togetPost2"
          style="width: 136px; height: 40px; margin-right: 4px"
          clearable
        >
          <el-option
            v-for="item in option22"
            :key="item.id"
            :label="item.deptmentName"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          v-model="SearchUser"
          placeholder="搜索人员"
          filterable
          style="width: 136px; height: 40px; margin-right: 4px"
          clearable
        >
          <el-option
            v-for="item in option4"
            :key="item.value"
            :label="item.userName"
            :value="item.userName"
          ></el-option>
        </el-select>
        <el-button
          type="primary"
          @click="Search"
          icon="el-icon-search"
          class="Search"
          style="
            width: 43px;
            background: #edf0f7;
            border: 1px solid #dbdbdb;
            border-radius: 4px;
            margin-right: 59px;
          "
        ></el-button>
        <!-- <el-button
          type="primary"
          @click="sureAdd"
          style="background: #5a8bff; width: 117px; border-radius: 2px"
          >添加到选中区 <i class="el-icon-arrow-right el-icon--right"></i
        ></el-button>
        <el-button
          type="primary"
          @click="showChoose"
          style="background: #5a8bff; width: 90px; border-radius: 2px"
          >查看选中区</el-button
        >-->

        <span
          style="
            display: flex;
            position: absolute;
            right: 20px;
            align-items: center;
          "
        >
          <img
            style="cursor: pointer; margin-right: 4px"
            @click="delChooseItem"
            src="../../assets/images/Examine_img/del.png"
            alt
          />

          <el-button
            @click="toSure"
            type="primary"
            style="background: #5a8bff; width: 77px; border-radius: 2px"
          >确定</el-button>
        </span>
      </div>
      <div class="tableBox">
        <div class="leftBox">
          <el-table
            style="width: 100%"
            v-loading="tableLoading"
            :data="option4"
            :header-cell-style="{
              background: '#EDF0F7',
              color: '#000000',
            }"
            ref="multipleTable"
            @selection-change="handleSelectionChange"
            height="400px"
          >
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column property="companyName" label="公司" width="120"></el-table-column>
            <el-table-column property="deptmentName" label="部门" width="120"></el-table-column>
            <el-table-column property="postName" label="岗位" width="120"></el-table-column>
            <el-table-column property="userName" label="人员" width="100"></el-table-column>
          </el-table>
        </div>
        <div class="rightBox">
          <el-table
            style="width: 100%"
            :data="Suretabel"
            :header-cell-style="{
              background: '#EDF0F7',
              color: '#000000',
            }"
            ref="multipleTable2"
            @selection-change="handleSelectionChange1"
            height="400px"
          >
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column property="companyName" label="公司" width="120"></el-table-column>
            <el-table-column property="deptmentName" label="部门" width="120"></el-table-column>
            <el-table-column property="postName" label="岗位" width="120"></el-table-column>
            <el-table-column property="userName" label="人员" width="100"></el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="选中区" width="45%" :visible.sync="dialogVisiblechoose">
      <div class="title">
        <div style="margin-bottom: 10px"></div>
        <span style="margin-left: 21px">选中区</span>
      </div>
      <div class="add_box_top">
        <el-select
          v-model="SearchUser"
          placeholder="搜索人员"
          filterable
          style="width: 136px; height: 40px; margin-right: 4px"
        >
          <el-option
            v-for="item in Suretabel"
            :key="item.value"
            :label="item.userName"
            :value="item.userName"
          ></el-option>
        </el-select>
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="searchDelP"
          class="Search"
          style="
            width: 43px;
            background: #edf0f7;
            border: 1px solid #dbdbdb;
            border-radius: 4px;
            margin-right: 343px;
          "
        ></el-button>

        <img
          style="cursor: pointer; margin-right: 4px"
          @click="delChooseItem"
          src="../../assets/images/Examine_img/del.png"
          alt
        />

        <el-button
          @click="toSure"
          type="primary"
          style="background: #5a8bff; width: 77px; border-radius: 2px"
        >确定</el-button>
      </div>
      <el-table
        :data="Suretabel"
        :header-cell-style="{
          background: '#EDF0F7',
          color: '#000000',
        }"
        ref="multipleTable2"
        @selection-change="handleSelectionChange1"
        height="400px"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column property="companyName" label="公司" width="120"></el-table-column>
        <el-table-column property="deptmentName" label="部门" width="120"></el-table-column>
        <el-table-column property="postName" label="岗位" width="120"></el-table-column>
        <el-table-column property="userName" label="人员"></el-table-column>
      </el-table>
    </el-dialog>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage4"
      :page-sizes="[10, 20, 30, 50, 80]"
      :page-size="curSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
    ></el-pagination>
  </div>
</template>

<script>
import axios from 'axios'
import { deleteMethod, get, post,baseURL } from '../../api/http'
export default {
  data() {
    return {
      BUrl: baseURL[process.env.NODE_ENV + ''],
      source: null,
      loading: false,
      tableLoading: false,
      elementHeight: 0,
      curIndex: 0,
      input1: '',
      gridData: [],
      dialogVisible: false,
      dialogVisible1: false,
      optionChoosevalue: null,
      dialogVisibleMore: false,
      deptmentName1: {},
      dialogVisiblechoose: false,
      Suretabel: [],
      date2: '',
      SearchUser: '',
      Name: '',
      dateValue: Number(new Date().getFullYear()),
      dateOption: [
        Number(new Date().getFullYear()) - 2,
        Number(new Date().getFullYear()) - 1,
        Number(new Date().getFullYear()),
        Number(new Date().getFullYear()) + 1,
        Number(new Date().getFullYear()) + 2,
      ],
      addtabel: [],
      typeValue: null,
      options: this.$store.state.companyUserList,
      Company1: '',
      company:'',
      form: {},
      selectValue: 0,
      typeValuOption: [
        { label: '考试/专题考试', value: '1' },
        { label: '学习/专题学习', value: '0' },
      ],
      option: [
        { label: '我的发布', value: 1 },
        { label: '我的任务', value: 0 },
      ],
      option2: [],
      option3: [],
      num: '',
      numPerson: '',
      Area1: {},
      userName: [],
      option4: [],
      userId: [],
      curE: null,
      type: null,
      endTime: '',
      tableData: [],
      postName: '',
      myCreateNum: '',
      aboutMy: '',
      start: 0,
      end: 10,
      date1: '',
      indexE: 1,
      curSize: 10,
      option6: [],
      theme: [],
      date: '',
      isSpecial: 1,
      isLean: 1,
      themeList: [],
      AssId: '',
      allshow: true,
      changeWork: '',
      optionChoose: [
        { name: '未完成', id: '0' },
        { name: '已完成', id: '1' },
      ],
      Company11: {},
      deptmentName11: '',
      option22: [],
      titlelist2: [],
      selectDrawVlue: [],
      companyList:[],
    }
  },
  filters: {
    time: function (e) {
      if (e === null) {
        return ''
      }
      return e.split('T')[0]
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.path == '/assignStudy' && from.path == '/Lean') {
        vm.isSpecial = 0
        sessionStorage.setItem('isSpecial', 0)
        sessionStorage.setItem('isLean', 1)
      } else if (to.path == '/assignStudy' && from.path == '/special-subject') {
        vm.isLean = 0
        sessionStorage.setItem('isLean', 0)
        sessionStorage.setItem('isSpecial', 1)
      } else if (to.path == '/assignStudy' && from.path == '/home') {
        vm.isLean = vm.$route.query.fromQuery.split('-')[0] == '文件' ? '1' : '0'
        vm.isSpecial = vm.$route.query.fromQuery.split('-')[0] == '专题' ? '1' : '0'
        sessionStorage.setItem(
          'isLean',
          vm.$route.query.fromQuery.split('-')[0] == '文件' ? '1' : '0'
        )
        sessionStorage.setItem(
          'isSpecial',
          vm.$route.query.fromQuery.split('-')[0] == '专题' ? '1' : '0'
        )
      } else {
        vm.isSpecial = Number(sessionStorage.getItem('isSpecial'))
        vm.isLean = Number(sessionStorage.getItem('isLean'))
      }
    })
  },
  mounted() {
    this.getCompanyList();
    this.getUser()
    this.getDate()
    this.$nextTick(() => {
      this.elementHeight =
        window.innerHeight -
        (document.getElementById('table').offsetTop + 60 + 62 + 10)
    })
  },
  methods: {
    back() {
      if(this.$route.query.fromQuery){
        this.$router.push({ path: '/page' })
      }else{
        this.$router.go(-1)
      }
    },
    // 查询
    getInquire(){
      if (this.selectValue == 1) {
        this.tableData = this.tableData2
        this.num = this.aboutMy
      } else {
        this.tableData = this.tableData1
        this.num = this.myCreateNum
      }

      if(this.dateValue){
        this.tableData = this.tableData.filter((i) => {
          if (i.saveTime) {
            i.saveTime = i.saveTime.split('-')[0]
          }
          return i.saveTime == this.dateValue
        })
      } 
      if(this.typeValue != null && this.typeValue != ''){
        if(this.typeValue == 0){
          this.tableData = this.tableData.filter(i =>
            i.isTest == 0 || i.isTest == 2
          )
        }
        if(this.typeValue == 1){
          this.tableData = this.tableData.filter(i =>
            i.isTest == 3 || i.isTest == 1 || i.isTest == 4
          )
        }
      }
      
      if(this.optionChoosevalue != null && this.optionChoosevalue != ''){
        if(this.optionChoosevalue == 1){
          this.tableData = this.tableData.filter(i =>
            i.status == 2
          )
        }
        if(this.optionChoosevalue == 0){
          this.tableData = this.tableData.filter(i =>
            i.status == 0
          )
        }
      }
      if(this.date1 != null && this.date1 != ''){
        this.tableData = this.tableData.filter(i =>{
          if(i.endTime){
            i.endTime = i.endTime.split('T')[0]
          }
          return i.endTime == this.date1
        })
      }
      
      if(this.company){
        this.tableData= this.tableData.filter(item => item.studyUserCompId == this.company)
      }
      
    },
    // 获取公司列表
    getCompanyList() {
      get('/api/Company/GetAllByUserStudy').then(res =>{
        if(res.code == 200){
          this.companyList = res.data;
        }
      })
    },
    optionChooseValueChange() {},
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage4 - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleCurrentChange(e) {
      this.currentPage4 = e
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    getDate() {
      get(
        '/api/AssignStudy/GetStudyTestByUser?UserId=' +
          JSON.parse(sessionStorage.getItem('userInfo')).id
      ).then((res) => {
        this.tableData1 = res.data
        if (this.isSpecial == 0) {
          this.tableData1 = this.tableData1.filter(
            (item) => item.isTest == 0 || item.isTest == 1 || item.isTest == 4
          )
        } else if (this.isLean == 0) {
          this.tableData1 = this.tableData1.filter(
            (item) => item.isTest == 2 || item.isTest == 3
          )
        }
        this.myCreateNum = this.tableData1.length
        get(
          '/api/AssignStudy/GetStudyTestByAssign?UserId=' +
            JSON.parse(sessionStorage.getItem('userInfo')).id
        )
          .then((res) => {
            this.tableData2 = res.data
            if (this.isSpecial == 0) {
              this.tableData2 = this.tableData2.filter(
                (item) =>
                  item.isTest == 0 || item.isTest == 1 || item.isTest == 4
              )
            } else if (this.isLean == 0) {
              this.tableData2 = this.tableData2.filter(
                (item) => item.isTest == 2 || item.isTest == 3
              )
            }
            this.aboutMy = this.tableData2.length
          })
          .then(() => {
            this.tableData1 = this.tableData1.filter((i) => {
              //console.log(i.endTime)
              if (i.saveTime) {
                i.saveTime = i.saveTime.split('-')[0]
              }
              //i.endTime = i.endTime.split('T')[0]
              return i.saveTime == this.dateValue
            })
            this.tableData2 = this.tableData2.filter((i) => {
              //console.log(i.endTime)
              if (i.saveTime) {
                i.saveTime = i.saveTime.split('-')[0]
              }
              //i.endTime = i.endTime.split('T')[0]
              return i.saveTime == this.dateValue
            })
            this.tableData =
              this.curIndex === 0 ? this.tableData1 : this.tableData2
            if (this.$route.query.fromQuery) {
              this.typeValue =
                this.$route.query.fromQuery.split('-')[1] == '学习' ? '0' : '1'
              this.changeTable2(
                this.$route.query.fromQuery.split('-')[1] == '学习' ? '0' : '1'
              )
            }
          }).then(() =>{
            this.getInquire();
          })
      })
      get('/api/SettingTheme/GetThemeMonth').then((res) => {
        this.option5 = res.data
      })
    },
    deltype() {
      let url
      switch (this.changeWork) {
        case 0:
          url = '/api/AssignStudy'
          break
        case 3:
          url = '/api/AssignThemeTest'
          break
        case 2:
          url = '/api/AssignThemeStudy'
          break
        case 1:
          url = '/api/AssignTest'
          break
        default:
          break
      }
      this.$confirm('此操作将永久删除该任务, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deleteMethod(url + '?Id=' + this.AssId).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: res.message,
              })
              this.tableData.forEach((i, index) => {
                if (i.id == this.AssId) {
                  this.tableData.splice(index, 1)
                }
              })
            } else {
              this.$message({
                type: 'info',
                message: res.message,
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    changeAssignTest() {
      if(this.userId.length == 0){
        this.$message.info('请选择人员')
        return
      }else if(!this.date){
        this.$message.info('请选择日期')
        return
      }
      let url
      switch (this.changeWork) {
        case 0:
          url = '/api/AssignStudy/TransferTask'
          break
        case 2:
          url = '/api/AssignThemeStudy/TransferTask'
          break
        case 3:
          url = '/api/AssignThemeTest/TransferTask'
          break
        case 1:
          url = '/api/AssignTest/TransferTask'
          break
        case 4:
          url = '/api/AssignAuditExamPlan/TransferTask'
          break
        default:
          break
      }
      const userIdList = []
      this.userId.forEach((i) => {
        userIdList.push(i.value)
      })
      let data = {
        assignUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
        assignUserName: JSON.parse(sessionStorage.getItem('userInfo')).userName,
        remark: '',
        theme: this.curE.theme,
        compId: this.curE.compId,
        compName: this.curE.compName,
        deptId: this.curE.deptId,
        deptName: this.curE.deptName,
        postId: this.curE.postId,
        postName: this.curE.postName,
        studyUserId: userIdList.toString(),
        studyUserName: this.userName.toString(),
        status: 0,
        endTime: this.date,
        saveUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
        saveUserName: JSON.parse(sessionStorage.getItem('userInfo')).userName,
      }
      post(url + '?AssignId=' + this.AssId, data).then((res) => {
        if (res.code == 200) {
          this.userName = []
          this.$message({
            type: 'success',
            message: res.message,
          })
          this.getDate()
          //this.change(this.curIndex)
          this.userId = []
          this.userName = []
        } else {
          this.$message({
            type: 'info',
            message: '失败',
          })
        }
        this.dialogVisible1 = false
        this.option4 = []
        this.Suretabel= []
      })
    },
    handleRow(e) {
      this.userId = []
      this.curE = e
      this.AssId = e.id
      this.changeWork = e.isTest
    },
    More() {
      // this.option4 = []
      this.dialogVisibleMore = true
      this.Company11 = ''
      this.deptmentName11 = ''
      this.SearchUser = ''
    },
    showChoose() {
      let arr = [...new Set(this.Sruetabel)]
      this.Sruetabel = arr
      this.dialogVisiblechoose = true
    },
    Search() {
      this.option4.forEach((i, index) => {
        if (i.userName == this.SearchUser) {
          let temp = this.option4[index]
          this.option4.splice(index, 1)
          this.option4.unshift(temp)
          //this.$refs.multipleTable.clearSelection()
        }
      })
    },
    searchDelP() {
      this.Sruetabel.forEach((i, index) => {
        if (i.userName == this.SearchUser) {
          let temp = this.Sruetabel[index]
          this.Sruetabel.splice(index, 1)
          this.Sruetabel.unshift(temp)
          //this.$refs.multipleTable.clearSelection()
        }
      })
    },
    changeData2(e) {
      this.dateValue = e;
      this.getInquire();
      // this.start = 0
      // this.end = 10
      // this.currentPage4 = 1
      // this.optionChoosevalue = ''
      // if (this.selectValue == 1) {
      //   this.tableData = this.tableData2
      //   this.num = this.aboutMy
      // } else {
      //   this.tableData = this.tableData1
      //   this.num = this.myCreateNum
      // }
      // if (e === null) {
      //   return
      // }
      // this.tableData = this.tableData.filter((i) => {
      //   //console.log(i.endTime)
      //   if (i.saveTime) {
      //     i.saveTime = i.saveTime.split('-')[0]
      //   }
      //   //i.endTime = i.endTime.split('T')[0]
      //   return i.saveTime == e
      // })
    },
    changeData(e) {
      this.date1 = e;
      this.getInquire();
      // if (this.dialogVisible || this.dialogVisible1) return
      // this.start = 0
      // this.end = 10
      // this.currentPage4 = 1
      // this.optionChoosevalue = ''
      // if (this.selectValue == 1) {
      //   this.tableData = this.tableData2
      //   this.num = this.aboutMy
      // } else {
      //   this.tableData = this.tableData1
      //   this.num = this.myCreateNum
      // }
      // if (this.typeValue == 1) {
      //   this.tableData = this.tableData.filter((i) => {
      //     return i.isTest == 3 || i.isTest == 1
      //   })
      // } else {
      //   this.tableData = this.tableData.filter((i) => {
      //     return i.isTest == 2 || i.isTest == 0
      //   })
      // }
      // if (e === null) {
      //   return
      // }
      // this.tableData = this.tableData.filter((i) => {
      //   if (i.endTime) {
      //     i.endTime = i.endTime.split('T')[0]
      //   }
      //   //i.endTime = i.endTime.split('T')[0]
      //   return i.endTime == e
      // })
    },
    changCompany(e){
      this.company = e;
      this.getInquire();
      // if (this.selectValue == 1) {
      //   this.tableData = this.tableData2
      //   this.num = this.aboutMy
      // } else {
      //   this.tableData = this.tableData1
      //   this.num = this.myCreateNum
      // }
      // if(e){
      //   this.tableData= this.tableData.filter(item => item.studyUserCompId == e)
      // }
    },
    change(num) {
      this.curIndex = num
      if (num == 1) {
        this.tableData = this.tableData2
        this.num = this.aboutMy
      } else {
        this.tableData = this.tableData1
        this.num = this.myCreateNum
      }
    },
    toclear() {
      if (this.selectValue == 1) {
        this.tableData = this.tableData2
        this.num = this.aboutMy
      } else {
        this.tableData = this.tableData1
        this.num = this.myCreateNum
      }
    },
    toSure() {
      let arr = [...new Set(this.Sruetabel)]
      this.Sruetabel = arr
      this.userId = [];
      this.userName= [];
      if (this.Sruetabel.label == 0) return
      this.Suretabel.forEach((i) => {
        this.userId.push({ value: i.id, label: i.userName })
        this.userName.push(i.userName)
      })
      this.dialogVisibleMore = false
      this.dialogVisiblechoose = false
      if(this.Suretabel.length > 0){
        this.numPerson = this.Suretabel[0].userName + '等' + this.userId.length + '位人'
      }else{
        this.numPerson = ''
      }
      // this.Suretabel = []
    },
    getUser() {
      this.loading = true
      get('/api/User').then((res) => {
        if (res.code == 200) {
          this.option6 = res.data
          this.loading = false
        }
      })
    },
    addEvent(e, v) {
      if (e === 4) {
        this.handleRow(v)
        this.numPerson = ''
        this.userName = []
        this.option4 = []
        this.Suretabel = []
        this.dialogVisible1 = true
        // this.getUser()
        return
      }
      this.userName = []
      this.userId = []
      this.Company1 = {}
      this.Company11 = {}
      this.deptmentName1 = ''
      this.deptmentName11 = ''
      this.option2 = []
      this.option22 = []
      this.Area1.value = ''
      this.numPerson = ''
      this.option3 = []
      this.option4 = []
      this.Suretabel = []
      this.SearchUser = ''
      this.date = ''
      this.type = e
      this.titlelist2 = []
      this.getUser()
      this.dialogVisible = true
    },
    sureAdd() {
      this.addtabel.forEach((i) => {
        this.Suretabel.push(i)
        this.Suretabel = this.Suretabel.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.id === item.id)
        )
      })
      // this.$message({
      //   type: 'success',
      //   message: '已把选中项添加到选中区',
      // })
    },
    changeTable(e) {
      this.selectValue = e

      this.start = 0
      this.end = 10
      this.currentPage4 = 1
      // this.optionChoosevalue = ''
      // this.typeValue = ''
      this.indexE = e
      if (e == 1) {
        this.tableData = this.tableData2
        this.num = this.aboutMy
      } else {
        this.tableData = this.tableData1
        this.num = this.myCreateNum
      }
      this.getInquire();
    },
    changeTable2(e) {
      this.typeValue = e;
      this.getInquire();
      // this.start = 0
      // this.end = 10
      // this.currentPage4 = 1
      // // this.optionChoosevalue = ''
      // this.indexE = e
      // if (this.selectValue == 1) {
      //   this.tableData = this.tableData2
      //   this.num = this.aboutMy
      // } else {
      //   this.tableData = this.tableData1
      //   this.num = this.myCreateNum
      // }
      // if (e === 1) {
      //   if (this.optionChoosevalue == null || this.optionChoosevalue == '') {
      //     this.tableData = this.tableData.filter((i) => {
      //       return i.isTest == 3 || i.isTest == 1 || i.isTest == 4
      //     })
      //   } else if (this.optionChoosevalue === 0) {
      //     this.tableData = this.tableData.filter((i) => {
      //       return i.status == 0
      //     })
      //   } else {
      //     this.tableData = this.tableData.filter((i) => {
      //       return i.status == 2
      //     })
      //   }
      // } else if (e === 0) {
      //   if (this.optionChoosevalue == null || this.optionChoosevalue == '') {
      //     this.tableData = this.tableData.filter((i) => {
      //       return i.isTest == 2 || i.isTest == 0
      //     })
      //   } else if (this.optionChoosevalue === 0) {
      //     this.tableData = this.tableData.filter((i) => {
      //       return i.status == 0
      //     })
      //   } else {
      //     this.tableData = this.tableData.filter((i) => {
      //       return i.status == 2
      //     })
      //   }
      // } else {
      //   if (this.selectValue == 1) {
      //     this.tableData = this.tableData2
      //     this.num = this.aboutMy
      //   } else {
      //     this.tableData = this.tableData1
      //     this.num = this.myCreateNum
      //   }
      // }
    },
    handleSelectionChange(e) {
      this.addtabel = e
      this.sureAdd()
    },
    changeDone(e) {
      this.optionChoosevalue = e;
      this.getInquire();
      // this.currentPage4 = 1
      // this.start = 0
      // this.end = 10
      // this.currentPage4 = 1
      // if (this.selectValue == 1) {
      //   this.tableData = this.tableData2
      //   this.num = this.aboutMy
      // } else {
      //   this.tableData = this.tableData1
      //   this.num = this.myCreateNum
      // }
      // if (e === 0) {
      //   this.tableData = this.tableData.filter((i) => {
      //     return i.status == 0
      //   })
      //   if (this.typeValue == 1) {
      //     this.tableData = this.tableData.filter((i) => {
      //       return i.isTest == 3 || i.isTest == 1 || i.isTest == 4
      //     })
      //   } else if (this.typeValue === 0) {
      //     this.tableData = this.tableData.filter((i) => {
      //       return i.isTest == 2 || i.isTest == 0
      //     })
      //   }
      // } else if (e == 1) {
      //   this.tableData = this.tableData.filter((i) => {
      //     return i.status == 2
      //   })
      //   if (this.typeValue == 1) {
      //     this.tableData = this.tableData.filter((i) => {
      //       return i.isTest == 3 || i.isTest == 1 || i.isTest == 4
      //     })
      //   } else if (this.typeValue === 0) {
      //     this.tableData = this.tableData.filter((i) => {
      //       return i.isTest == 2 || i.isTest == 0
      //     })
      //   }
      // } else if (e == '') {
      //   if (this.typeValue == 1) {
      //     this.tableData = this.tableData.filter((i) => {
      //       return i.isTest == 3 || i.isTest == 1 || i.isTest == 4
      //     })
      //   } else if (this.typeValue === 0) {
      //     this.tableData = this.tableData.filter((i) => {
      //       return i.isTest == 2 || i.isTest == 0
      //     })
      //   }
      // }
      // if (this.date1) {
      //   this.tableData = this.tableData.filter((i) => {
      //     i.endTime = i.endTime.split('T')[0]
      //     return i.endTime == this.date1
      //   })
      // }
      // this.currentPage4 = 1
    },
    toTest(row) {
      this.$router.push({
        path: '/AssignTest',
        query: {
          studyUserId: row.studyUserId,
          studyUserName: row.studyUserName,
          AssId: row.id,
          isTheme: row.isTest,
          compId: row.compId,
          deptId: row.deptId,
          postId: row.postId,
        },
      })
    },
    toLearn(row) {
      if (row.isTest == 2) {
        this.$router.push({
          path: '/AspSubject',
          query: {
            AssId: row.id,
            isTheme: row.isTest,
            theme: row.theme,
          },
        })
      } else {
        this.$router.push({
          path: '/Lean',
          query: {
            compId: row.compId,
            deptId: row.deptId,
            postId: row.postId,
            AssId: row.id,
            isTheme: row.isTest,
          },
        })
      }
    },
    togetArea(e) {
      this.source = axios.CancelToken.source()
      get('/api/Department/GetNoTree?CompId=' + e.value).then((res) => {
        if (!this.dialogVisibleMore) {
          this.option2 = res.data
          this.deptmentName1 = {}
          this.Area1 = {}
        } else {
          this.option22 = res.data
          this.tableLoading = true
          this.deptmentName11 = ''
          this.SearchUser = ''
          axios
            .get(this.BUrl + '/api/User/GetByCompId?CompId=' + e.value, {
              cancelToken: this.source.token,
              headers: {
                Authorization:
                  'Bearer ' +
                  JSON.parse(window.sessionStorage.getItem('token')),
              },
            })
            .then((res) => {
              if (res.data.code == 200) {
                this.option4 = res.data.data
                this.addtabel = this.option4
                // this.SearchUser = ''
                this.tableLoading = false
              }
            })
            .catch((error) => {
              if (axios.isCancel(error)) {
                console.log('Request canceled', error.message)
              } else {
                // 处理错误
              }
            })

          // get('/api/User/GetByCompId?CompId=' + e.value).then((res) => {
          //   if(res.code == 200){
          //     this.option4 = res.data
          //     this.addtabel = this.option4
          //     this.SearchUser = ''
          //     this.tableLoading = false
          //   }
          // })
        }
        this.userId = []
      })
    },
    changeList(e) {
      this.themeList = e.toString()
    },
    togetPost(e) {
      get(
        '/api/Post?CompId=' + this.Company1.value + '&DeptId=' + e.value
      ).then((res) => {
        this.option3 = res.data
        this.Area1 = {}
        this.userId = []
      })
    },
    togetPost2(e) {
      this.tableLoading = false
      if (this.source) {
        this.source.cancel() // 取消请求
      }
      get(
        '/api/User/GetByCompByDept?CompId=' +
          this.Company11.value +
          '&DeptId=' +
          e
      ).then((res) => {
        this.option4 = res.data
        this.addtabel = this.option4
        this.SearchUser = ''
        // this.$refs.multipleTable.clearSelection()
        // if (this.dialogVisibleMore) {
        //   this.$refs.multipleTable.toggleAllSelection()
        // }
      })
    },
    // 岗位获取文件
    changePost(e) {
      this.filePost = e
      this.getTitle2(e)
    },
    getTitle2(e) {
      var url = '/api/MixAudit/GetTestTitleList'
      get(
        url +
          '?CompId=' +
          this.Company1.value +
          '&DeptId=' +
          this.deptmentName1.value +
          '&PostId=' +
          e
      ).then((res) => {
        this.titlelist2 = res.data
        this.selectDrawVlue = []
      })
    },
    selectionDraw(e) {
      this.selectDrawVlue = e
    },
    sss(e) {
      this.userName = []
      e.forEach((i) => {
        this.userName.push(i.label)
      })
    },
    delChooseItem() {
      this.delList.forEach((i) => {
        this.Suretabel.splice(
          this.Suretabel.findIndex((item) => item.id === i.id),
          1
        )
      })
    },
    handleSelectionChange1(e) {
      this.delList = e
    },
    addAssignTest() {
      if((!this.Company1 || !this.deptmentName1 || !this.Area1.value) && (this.type != 2 && this.type != 3)){
        this.$message({
          type: 'info',
          message: '请选择公司部门岗位',
        })
        return
      }
      if (this.userId.length == 0) {
        this.$message({
          type: 'info',
          message: '请选择考核人员',
        })
        return
      } else if (this.date == '') {
        this.$message({
          type: 'info',
          message: '请选择时间',
        })
        return
      }
      let url
      switch (this.type) {
        case 1:
          url = '/api/AssignStudy'
          break
        case 2:
          url = '/api/AssignThemeTest'
          break
        case 3:
          url = '/api/AssignThemeStudy'
          break
        case 0:
          url = '/api/AssignTest'
          // url = '/api/MixAuditHead/InsertAssignAuditTestOnly'
          break
        default:
          break
      }
      const userIdList = []
      this.userId.forEach((i) => {
        userIdList.push(i.value)
      })
      var postName = ''
      this.option3.forEach((element) => {
        if (element.id === this.Area1.value) {
          postName = element.postName
        }
      })
      this.curIndex = this.selectValue
      this.dialogVisible = false
      var data = {}
      if (this.type == 0) {
        var fileName = []
        var fileNo = []
        this.selectDrawVlue.map((i) => {
          fileName.push(i.fileName)
          fileNo.push(i.fileNo)
        })
        data = {
          assignUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
          assignUserName: JSON.parse(sessionStorage.getItem('userInfo'))
            .userName,
          remark: '',
          studyUserId: userIdList.toString(),
          studyUserName: this.userName.toString(),
          compId: this.Company1.value,
          compName: this.Company1.label,
          deptId: this.deptmentName1.value,
          deptName: this.deptmentName1.label,
          postId: this.Area1.value,
          postName: postName,
          fileNo: fileNo.toString(),
          fileName: fileName.toString(),
          endTime: this.date,
          percent: 100,
        }
      } else {
        data = {
          assignUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
          assignUserName: JSON.parse(sessionStorage.getItem('userInfo'))
            .userName,
          remark: '',
          studyUserId: userIdList.toString(),
          studyUserName: this.userName.toString(),
          compId: this.Company1.value,
          compName: this.Company1.label,
          deptId: this.deptmentName1.value,
          deptName: this.deptmentName1.label,
          postId: this.Area1.value,
          postName: postName,
          status: 0,
          score: 0,
          endTime: this.date,
          theme: this.themeList,
          saveUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
          saveUserName: JSON.parse(sessionStorage.getItem('userInfo')).userName,
        }
      }
      post(url, data)
        .then((res) => {
          if (res.code == 200) {
            this.userName = []
            this.$message({
              type: 'success',
              message: res.message,
            })
            this.getDate()
            //this.change(this.curIndex)
            this.Company1.value = ''
            this.deptmentName1.value = ''
            this.Area1.value = ''
            this.userId = []
            this.userName = []
            this.addtabel = []
            this.numPerson = ''
            this.Suretabel = []
            this.option4 = []
            this.date = ''
          } else {
            this.$message({
              type: 'info',
              message: '失败',
            })
          }
          this.dialogVisible = false
          this.option4 = []
          this.Suretabel= []
        })
        .then(() => {
          this.changeTable(this.selectValue)
        })
    },
  },
}
</script>
<style scoped>
.el-table__body tr.current-row > td {
  background: #2281ee !important;
}
</style>
<style lang="less" scoped>
::v-deep .el-form-item__content .el-input-group{
  vertical-align: middle !important;
}
.class_box {
  box-sizing: border-box !important;
}
/deep/.Search i {
  color: rgb(0, 0, 0);
  line-height: 30px !important;
}
.title {
  width: 100%;
  height: 45px;
  background: #ffffff;
  position: absolute;
  top: 0;
  left: 0;

  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
}
/deep/.el-pagination {
  position: absolute;
  right: 0;
  bottom: 30px;
}
/deep/.el-dialog__body {
  padding-top: 0px;
}
.Top_box {
  width: 100%;
  height: 55px;
  background: #4274c0;
  display: flex;
  align-items: center;
  span {
    width: 64px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    margin-left: 10px;
  }
}
.add_box_top {
  display: flex;
  align-items: center;
}
.tableBox {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 16px);
  .leftBox {
    margin-right: 8px;
    width: 50%;
  }
  .rightBox {
    margin-left: 8px;
    width: 50%;
  }
}
/deep/.el-select {
  display: flex;
  align-items: center;
}
.select_box {
  height: 30px;
  position: relative;
}
.bottom_box {
  display: flex;
  background: #ffffff;
  width: 100%;
  .bottom_box_left {
    width: 336px;
    height: 870px;
    border-right: 3px solid #f1f1f1;
    .title {
      width: 96px;
      height: 24px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
      margin-top: 30px;
      margin-left: 49px;
      margin-bottom: 53px;
    }
    span {
      width: 35px;
      height: 18px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
      margin-left: 49px;
    }
    .test1 {
      margin-left: 49px;
      width: 260px;
      margin-top: 23px;
      border-bottom: 3px solid #e7ecf2;
      .test_top {
        width: 330px;
        height: 57px;
        display: flex;
        align-items: center;
        div {
          width: 36px;
          height: 18px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
        }
      }
      .item {
        width: 260px;
        height: 40px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        margin-left: -11px;
        display: flex;
        align-items: center;
        padding-left: 11px;
        cursor: pointer;
      }
      .item:nth-child(3) {
        margin-bottom: 20px;
      }
    }
  }
  .bottom_box_right {
    width: 100%;
    .top {
      width: 72px;
      height: 18px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
      margin-top: 20px;
      margin-left: 24px;
    }
    .mid {
      width: 100%;
      height: 70px;
      margin-left: 31px;
      display: flex;
      align-items: center;
    }
  }
}
img {
  width: 30px;
}
.active {
  background: #e8ebf4;
  color: #3888db;
}

/deep/ .el-input__inner {
  background-color: #edf0f7 !important;
  border-radius: 0 !important;
}
/deep/.el-form-item {
  margin-bottom: 12px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
}
/deep/.el-select__tags {
  position: absolute;
  bottom: 0;
}
/deep/.el-input__inner {
  position: relative;
  //height: 30px !important;

  font-size: 12px !important;
  // white-space: nowrap !important;
  line-height: 0 !important;
}
/deep/ .el-table__body-wrapper{
  height: calc(100% - 40px) !important;
}
</style>
